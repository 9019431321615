<template>
  <div id="home">
    <div class="jumbotron jumbotron-fluid"></div>
    <div class="all-movies">
      <div class="new-movie" v-if="user && user.isAdmin">
        <b-button v-if="user && !adding" v-on:click="toggleAdd()" variant="primary">
          <font-awesome-icon icon="pen"></font-awesome-icon>
          New Movie
        </b-button>
        <form id="new-movie-form" v-if="adding">
          <b-form-input type="text" placeholder="Movie Name" v-model="newMovie.movieName"></b-form-input>
          <b-form-input type="date" placeholder="Release Date" v-model="newMovie.movieDate"></b-form-input>
          <b-form-input type="text" placeholder="Banner URL" v-model="newMovie.movieBanner"></b-form-input>
          <b-form-input type="text" placeholder="Poster URL" v-model="newMovie.moviePoster"></b-form-input>
          <b-button v-on:click="addMovie()" variant="success">Add Movie</b-button>
          <b-button v-on:click="toggleAdd()" variant="info">Cancel</b-button>
        </form>
      </div>
      <div v-for="(movieList, index) in [comingSoon, newReleases, movies]" :key="index">
        <h2>{{ getTitle(index) }}</h2>
        <div class="msg" v-if="movieList.length == 0">No movies in this category</div>
        <div class="movies-wrapper fade-right">
          <font-awesome-icon icon="arrow-circle-left" class="arrow arrow-left" v-on:click="doScroll($event, false)"></font-awesome-icon>
          <div class="movies" v-on:scroll="onScroll($event)">
            <div class="movie" v-for="(movie, index) in movieList" :key="index">
              <router-link :to="{ name: 'TrailerPage', params: { id: movie.movieId } }">
                <img :src="movie.moviePoster" onerror="this.style.width='20vw';" :alt="movie.movieName">
              </router-link>
            </div>
            <div class="space">space</div>
          </div>
          <font-awesome-icon icon="arrow-circle-right" class="arrow arrow-right" v-on:click="doScroll($event, true)"></font-awesome-icon>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import DBHelper from '../services/db';
  import { auth } from '../services/firebase';
  let db = new DBHelper();

  export default {
      name: 'Home',
        methods: {
          toggleAdd: function() {
            this.adding = !this.adding;
          },
          getTitle: function(index) {
            switch (index) {
              case 0:
                return 'Upcoming Movies';
              case 1:
                return 'New Releases';
              case 2:
                return 'Most Upvoted';
            }
          },
          loadMovies: async function() {
              const self = this;
              await db.getMovies(function(movies) {
                self.movies = movies;
                self.comingSoon = movies.filter(movie => Date.parse(movie.movieDate) > Date.now())
                  .sort((m1, m2) => m1.movieDate < m2.movieDate ? -1 : 1);
                self.newReleases = movies.filter(movie => {
                  return Date.now() - Date.parse(movie.movieDate) < 7776000000 &&
                    Date.now() > Date.parse(movie.movieDate);
                }).sort((m1, m2) => m1.movieDate > m2.movieDate ? -1 : 1);
                self.loaded = true;
              });
          },
          addMovie: async function() {
            await db.addMovie(this.newMovie)
            this.newMovie = {}
          },
          onScroll: function(event) {
            if (event.target.scrollLeft <= 0) {
              event.target.parentElement.classList.remove('fade-left')
            } else {
              event.target.parentElement.classList.add('fade-left')
            }
            if (event.target.offsetWidth + event.target.scrollLeft >= event.target.scrollWidth) {
              event.target.parentElement.classList.remove('fade-right')
            } else {
              event.target.parentElement.classList.add('fade-right')
            }
          },
          doScroll(event, isRight) {
            let parent = event.target.parentElement;
            if (parent.tagName.toLowerCase() === 'svg') {
              parent = parent.parentElement;
            }
            const siblings = parent.childNodes;
            let moviesDiv;
            for (let i = 0; i < siblings.length; i++) {
              if (siblings[i].className === 'movies') {
                moviesDiv = siblings[i];
                break;
              }
            }
            moviesDiv.scroll({
              left: isRight ? moviesDiv.offsetWidth + moviesDiv.scrollLeft : moviesDiv.scrollLeft - moviesDiv.offsetWidth,
              behavior: 'smooth'
            });
          }
      },
      mounted: function() {
        document.title = 'Home | 1Up Trailers'
        const self = this;
        this.loadMovies();
        auth.onAuthStateChanged(async function(user) {
          if (user) {
            user.isAdmin = await db.isUserAdmin(user);
          }
          self.user = user;
        });
      },
      data: function() { 
        return {
          movies: [],
          comingSoon: [],
          newReleases: [],
          newMovie: {},
          loaded: false,
          user: null,
          adding: false,
        }
      }
  }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
  .all-movies {
    background: #333;
    padding: 48px 0;
  }

  // .fade-left:before {
  //   content: "";
  //   position: absolute;
  //   z-index: 1;
  //   top: 0;
  //   left: 0;
  //   pointer-events: none;
  //   background: linear-gradient(to right, 
  //                     rgba(204,204,204, 1), 
  //                     rgba(204,204,204, 0) 10%);
  //   width: 100%;
  //   height: 100%;
  // }

  // .fade-right:after {
  //   content: "";
  //   position: absolute;
  //   z-index: 1;
  //   top: 0;
  //   right: 0;
  //   pointer-events: none;
  //   background: linear-gradient(to left, 
  //                     rgba(204,204,204, 1), 
  //                     rgba(204,204,204, 0) 20%);
  //   width: 100%;
  //   height: 100%;
  // }

  h2 {
    padding: 0 4vw;
  }

  .movies-wrapper {
    position: relative;
    width: 100%;

    .arrow {
      position: absolute;
      top: 35%;
      display: none;
      opacity: 0.6;
      color: white;
      font-size: 48pt;
      cursor: pointer;

      &.arrow-left {
        left: 12px;
      }

      &.arrow-right {
        right: 12px;
      }

      &:hover {
        opacity: 0.8;
      }
    }

    &.fade-right {
      .arrow-right {
        display: block;
      }
    }

    &.fade-left {
      .arrow-left {
        display: block;
      }
    }
  }

  .movies {
    display: flex;
    flex-flow: row nowrap;
    overflow-x: scroll;
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
    margin: 24px 0 64px;
    padding: 0 4vw;

    &::-webkit-scrollbar {
      display: none;
    }

    .space {
      width: 4vw;
      height: 100px;
      color: rgba(0,0,0,0);
    }
  }
  
  .movie {
    margin-right: 18px;
    margin-bottom: 10px;
    box-shadow: -2px 2px 5px rgba($color: #000, $alpha: 0.6);

    &:hover {
      box-shadow: -4px 4px 8px rgba($color: #000, $alpha: 0.6);
    }

    img {
      height: 28vw;
      min-height: 280px;
      min-width: 150px;
    }

    h3 {
      font-size: 14pt;
      width: 120px;
      padding: 10px;
    }
  }

  .new-movie {
    margin: 18px 4vw 48px;
    max-width: 300px;

    input {
      margin-bottom: 6px;
    }

    button {
      margin-right: 6px;
    }
  }

  .jumbotron {
    height: 420px;
    color: white;
    background: url("../assets/images/banner.jpg") no-repeat center 60%;
    background-size: cover;
    margin-bottom: 0;
  }

  .msg {
    padding: 36px 4vw 18px;
  }
</style>
