import Vue from 'vue'
import App from './App.vue'
import TrailerPage from './components/TrailerPage.vue'
import LogIn from './components/LogIn.vue'
import Terms from './components/Terms.vue'
import Home from './components/Home.vue'
import VueRouter from 'vue-router'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faThumbsUp, faThumbsDown, faPen, faArrowCircleLeft, faArrowCircleRight, faTrashAlt, faArrowLeft } from '@fortawesome/free-solid-svg-icons'
import { faFacebookF, faFacebook, faFacebookSquare } from '@fortawesome/free-brands-svg-icons'
import { BootstrapVue } from 'bootstrap-vue'
import 'firebaseui/dist/firebaseui.css'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'


const routes = [
  { path: '/', name: 'Home', component: Home },
  { path: '/logIn', name: 'Log In', component: LogIn },
  { path: '/movies/:id', name: 'TrailerPage', component: TrailerPage },
  { path: '/terms', name: 'Terms', component: Terms }
]

const router = new VueRouter({
  mode: 'history',
  routes
})

Vue.config.productionTip = false
Vue.use(VueRouter)
Vue.use(BootstrapVue)

library.add(faThumbsUp, faThumbsDown, faPen, faArrowCircleLeft, faArrowCircleRight, faTrashAlt, faArrowLeft, faFacebookF, faFacebookSquare, faFacebook)
Vue.component('font-awesome-icon', FontAwesomeIcon)

new Vue({
  render: h => h(App),
  router
}).$mount('#app')
