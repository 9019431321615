<template>
    <main id="trailer-page" v-if="loaded">
      <div class="jumbotron jumbotron-fluid" :style="'background-image:url(' + movie.movieBanner + '),url(https://source.unsplash.com/OaVJQZ-nFD0)'">
        <div class="container">
          <b-button v-if="user && user.isAdmin" variant="outline-light" v-b-modal.banner-modal>
            <font-awesome-icon icon="pen"></font-awesome-icon>
            Change Banner
          </b-button>
        </div>
      </div>
      <b-modal id="banner-modal" @ok="changeBanner()" @cancel="refresh()">
        <h2>Banner for {{ movie.movieName }}</h2>
        <b-form-file placeholder="Upload a File (Coming Soon)" disabled accept="image/*" v-model="movie.uploadedImg"></b-form-file>
        <div>or</div>
        <b-input type="text" placeholder="Enter a URL" v-model="movie.movieBanner"></b-input>
      </b-modal>
      <div class="header">
        <img :src="movie.moviePoster" :alt="movie.movieName + ' Poster'">
        <div class="movie-info">
          <h1>{{ movie.movieName }}</h1>
          <h3>Release Date: {{ formatDate(movie.movieDate) }}</h3>
          <h3>{{ trailers.length + ' trailer' + (trailers.length == 1 ? '' : 's') }}</h3>
        </div>
      </div>    
      <div class="trailer-content">    
        <div class="new-trailer" v-if="user && user.isAdmin">
          <b-button v-if="!adding" v-on:click="toggleAdd()" variant="primary">
            <font-awesome-icon icon="pen"></font-awesome-icon>
            New Trailer
          </b-button>
          <div id="new-trailer-form" v-if="adding">
            <b-input-group>
              <template v-slot:prepend>
                <b-button v-on:click="toggleAdd()" variant="primary">            
                  <font-awesome-icon icon="arrow-left"></font-awesome-icon>
              </b-button>
              </template>
              <b-input autocomplete="off" v-on:keyup.enter.native="searchYoutube()" type="text" v-model="ytQuery"></b-input>
              <template v-slot:append>
                <b-button v-on:click="searchYoutube()" variant="success">Search</b-button>
              </template>
            </b-input-group>
            <div class="yt-results">
              <div class="yt-msg" v-if="ytResults.length == 0">Use the search bar above to find trailers to add.</div>
              <div class="yt-result" v-for="(result, index) in ytResults" :key="index">
                <img :src="result.thumbnail" alt="" v-b-modal="'yt-preview-modal-' + result.trailer.trailerYoutubeId">
                <div class="result-info">
                  <h5 class="trailer-name">{{ result.trailer.trailerName }}</h5>
                  <h3 class="trailer-channel">{{ result.channel }}</h3>
                  <h3 class="trailer-date">{{ formatDate(result.trailer.trailerDate).toUpperCase() }}</h3>
                  <b-button v-on:click="addTrailer(result.trailer)" :variant="trailerAdded(result.trailer) ? 'info' : 'success'" :disabled="trailerAdded(result.trailer)">{{ trailerAdded(result.trailer) ? 'Added' : 'Add' }}</b-button>
                </div>
                <b-modal :id="'yt-preview-modal-' + result.trailer.trailerYoutubeId" hide-footer>
                  <iframe
                    :src="'https://www.youtube.com/embed/' + result.trailer.trailerYoutubeId" 
                    frameborder="0" 
                    width="100%"
                    height="300px"
                    allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" 
                    allowfullscreen>
                  </iframe> 
                </b-modal>
              </div>
            </div>
          </div>
        </div>
        <div class="msg" v-if="trailers.length == 0">No trailers yet for this movie.</div>
        <div class="trailer" v-for="(trailer, index) in trailers" :key="index">
          <h2>
            {{ trailer.title }}   
            <font-awesome-icon 
              icon="trash-alt" 
              style="color:red;cursor:pointer;" 
              v-on:click="deleteTrailer(trailer.trailerId)"
              v-if="user && user.isAdmin"
            >
            </font-awesome-icon>
          </h2>
          <h3>{{ formatDate(trailer.date).toUpperCase() }}</h3>
            <div class="youtube">
              <iframe
              :src="'https://www.youtube.com/embed/' + trailer.youtubeId" 
              frameborder="0" 
              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" 
              allowfullscreen>
              </iframe> 
            </div>
            <div class="votes">
              <div class="vote vote-accuracy">
                <div class="noselect">
                    <font-awesome-icon icon="thumbs-up" v-on:click="accuracyUp(trailer.trailerId)"></font-awesome-icon>
                    {{ trailer.accuracyUp.length }}
                    <font-awesome-icon icon="thumbs-down" v-on:click="accuracyDown(trailer.trailerId)"></font-awesome-icon>  
                    {{ trailer.accuracyDown.length }}
                </div>
                <div class="vote-label">ACCURATE</div>
              </div>
              <div class="vote vote-spoiler">
                <div class="noselect">
                    <font-awesome-icon icon="thumbs-up" v-on:click="spoilerUp(trailer.trailerId)"></font-awesome-icon>
                    {{ trailer.spoilersUp.length }}
                    <font-awesome-icon icon="thumbs-down" v-on:click="spoilerDown(trailer.trailerId)"></font-awesome-icon>
                    {{ trailer.spoilersDown.length }}  
                </div>
                <div class="vote-label">SPOILER FREE</div>
              </div>
            </div>
          </div>
          <small>The movie poster displayed above is for {{ movie.movieName }}. The poster art copyright is believed to belong to the distributor of the item promoted{{ movie.movieDistributor ? (', ' + movie.movieDistributor) : '' }}, the publisher{{ movie.moviePublisher ? (', ' + movie.moviePublisher) : '' }}, or the graphic artist. <a :href="movie.moviePoster">Source Link</a></small>
      </div>
    </main>
</template>

<script>
  import DBHelper from '../services/db';
  import { auth } from '../services/firebase';

  let db = new DBHelper();

  export default {
      name: 'TrailerPage',
      props: {
        bannerUrl: String
      },
      methods: {
          refresh: function() {
            window.location.reload();
          },
          formatDate: function(date) {
            return new Date(date).toLocaleDateString('en-US', {month: 'long', day: 'numeric', year: 'numeric'})
          },
          toggleAdd: function() {
            this.adding = !this.adding;
            if (this.adding) {
              this.searchYoutube();
            }
          },
          searchYoutube: function() {
            if (!this.ytQuery) return;
            const self = this;
            var xhr = new XMLHttpRequest();
            var url = 'https://www.googleapis.com/youtube/v3/search?maxResults=25&part=snippet&q=' + encodeURIComponent(this.ytQuery) + '&key=' + process.env.VUE_APP_YT_API;
            xhr.open('GET', url, false);
            xhr.onreadystatechange = function () {
              if (this.readyState == XMLHttpRequest.DONE) {
                if (this.status == 200) {
                  self.ytResults = JSON.parse(this.responseText).items.map(item => {
                    // decode html entity codes in the title
                    const encodedTitle = item.snippet.title;
                    const textarea = document.createElement('textarea');
                    textarea.innerHTML = encodedTitle;
                    const title = textarea.value;
                    return {
                      trailer: {
                        trailerMovie: self.$route.params.id,
                        trailerDate: item.snippet.publishedAt.split('T')[0],
                        trailerYoutubeId: item.id.videoId,
                        trailerName: title
                      },
                      thumbnail: item.snippet.thumbnails.medium.url,
                      channel: item.snippet.channelTitle
                    }
                  });
                }
              }
            }
            xhr.send();
          },
          trailerAdded: function(trailer) {
            for (var t of this.trailers) {
              if (t.youtubeId == trailer.trailerYoutubeId) {
                return true;
              } 
            }
            return false;
          },
          loadTrailers: async function() {
            const self = this;
            await db.getTrailersForMovie(this.$route.params.id, function(trailers) {
                self.trailers = trailers;
                self.trailers.sort((t1, t2) => t1.date > t2.date ? -1 : 1);
                self.loaded = true;
            });
          },
          loadMovie: async function() {
            const self = this;
            await db.getMovieById(this.$route.params.id, function(movie) {
                self.movie = movie;
                document.title = movie.movieName + ' | 1UpTrailers';
                self.ytQuery = movie.movieName + ' trailers';
            });
          },
          addTrailer: async function(trailer) {
            await db.addTrailer(trailer)
          },
          deleteTrailer: function(id) {
            this.$bvModal.msgBoxConfirm('Are you sure you want to delete this trailer? This cannot be undone and will lose all votes for this trailer permanently.',
            {
              title: 'Are you sure?',
              okVariant: 'danger',
              okTitle: 'Yes, Delete Permanently'
            }).then(confirmed => {
              if (confirmed) {
                db.deleteTrailer(id);
              }
            });
          },
          accuracyUp: async function(id) {
              const user_id = this.user && this.user.uid ? this.user.uid : this.ip;
              db.rateAccuracy(id, user_id, true)
          },
          accuracyDown: async function(id) {
              const user_id = this.user && this.user.uid ? this.user.uid : this.ip;
              db.rateAccuracy(id, user_id, false)
          },
          spoilerUp: async function(id) {
              const user_id = this.user && this.user.uid ? this.user.uid : this.ip;
              db.rateSpoilers(id, user_id, true)
          },
          spoilerDown: async function(id) {
              const user_id = this.user && this.user.uid ? this.user.uid : this.ip;
              db.rateSpoilers(id, user_id, false)
          },
          changeBanner: async function() {
            if (this.movie.uploadedImg) {
              // do something
              console.log(this.movie.uploadedImg)
            } else {
              db.updateMovie(this.$route.params.id, {movieBanner: this.movie.movieBanner});
            }
          },
      },
      mounted: function() {
        const self = this;
        this.loadTrailers();
        this.loadMovie();
        var xhr = new XMLHttpRequest();
        var url = 'https://api.ipify.org?format=json';
        xhr.open('GET', url, false);
        xhr.onreadystatechange = function () {
          if (this.readyState == XMLHttpRequest.DONE) {
            if (this.status == 200) {
              self.ip = JSON.parse(this.responseText).ip;
            }
          }
        }
        xhr.send();
        auth.onAuthStateChanged(async function(user) {
          if (user) {
            user.isAdmin = await db.isUserAdmin(user);
          }
          self.user = user;
        });
      },
      data: function() { 
        return {
          trailers: [],
          ytQuery: null,
          ytResults: [],
          movie: {},
          loaded: false,
          user: null,
          ip: null,
          adding: false
        }
      }
  }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.trailer-content {
    background: #333;
    padding: 48px 4vw;
}

.trailer-name {
    color: black
}

.youtube {
  width: 100%;

  iframe {
    width: 100%;
    height: calc(92vw * 0.5625);
    max-height: 360px;
  }
}

.jumbotron {
  height: 50vh;
  color: white;
  background-position: center 33%;
  background-size: cover;
  background-repeat: no-repeat;
  margin-bottom: 0;
}

h1 {
  font-size: 48pt;
  margin-bottom: 24px;

  @media screen and (max-width: 600px) {
    font-size: 36px;
  }
}

.header {
  display: flex;
  align-items: center;
  overflow: hidden;
  padding: 48px 4vw;
  background: #555;

  img {
    height: 240px;
  }

  .movie-info {
    margin-left: 36px;

      @media screen and (max-width: 600px) {
        margin-left: 18px;
      }
  }
}

h3 {
  font-size: 11pt;
  color: #ccc;
}

.trailer {
  margin-top: 36px;
  background: #555;
  max-width: 92vw;
  width: 640px;
  border-radius: 8px;
  box-shadow: 0 2px 12px rgba($color: #000, $alpha: 0.1);
  padding: 28px 0 18px;

  > *:not(.youtube) {
    padding-left: 18px;
    padding-right: 18px;
  }

  .youtube {
    margin-top: 18px;
  }
}

.votes {
  width: 100%;
  font-size: 18pt;
  display: flex;
  justify-content: space-around;
  padding-top: 20px;
}

.vote {
  padding-right: 24px;
}

.vote-label {
  font-size: 10pt;
  text-align: center;
  font-weight: bold;
}

.fa-thumbs-up, .fa-thumbs-down {
  cursor: pointer;
}

.fa-thumbs-down {
  margin-left: 8px;
}

.vote-accuracy {
  .fa-thumbs-up, .fa-thumbs-down {
    color: #c3c3ff;
    font-size: 18pt;

    &:hover {
      color: white;
    }

    &.selected {
      color: white;
    }
  }
}

.vote-spoiler {
  .fa-thumbs-up, .fa-thumbs-down {
    color: #c3c3ff;
    font-size: 18pt;

    &:hover {
      color: white;
    }

    &.selected {
      color: white;
    }
  }
}

.new-trailer {
  width: 640px;
  max-width: 100%;
  margin: 24px 0 36px;

  .yt-results {
    background: white;
    max-height: 400px;
    overflow-y: scroll;
    border: 1px solid lightgrey;
    border-radius: 0 0 6px 6px;

    .yt-msg {
      padding: 20px;
    }

    .yt-result {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      justify-content: space-between;
      padding: 16px;
      border-bottom: 1px solid lightgrey;

      > * {
        width: 48%;
      }
    }
  }
}

small {
  display: block;
  margin-top: 32px;
}

.msg {
  margin: 40px 0;
}

</style>
