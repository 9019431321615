<template>
  <div id="wrapper">
    <div id="firebaseui"></div>
  </div>
</template>

<script>
import { auth } from '@/services/firebase'
import firebase from 'firebase/compat/app'
import * as firebaseui from 'firebaseui'

export default {
  name: 'LogIn',
  methods: {
  },
  mounted: function() {
      var uiConfig = {
          signInSuccessUrl: process.env.VUE_APP_URL,
          signInOptions: [
            //   firebase.auth.GoogleAuthProvider.PROVIDER_ID,
            //   firebase.auth.FacebookAuthProvider.PROVIDER_ID,
              firebase.auth.EmailAuthProvider.PROVIDER_ID,
          ],
          // tosUrl and privacyPolicyUrl accept either url string or a callback
          // function.
          // Terms of service url/callback.
          // tosUrl: '<your-tos-url>',
          // Privacy policy url/callback.
          // privacyPolicyUrl: function() {
          //   window.location.assign('<your-privacy-policy-url>');
          // }
      };
      if (!firebaseui.auth.AuthUI.getInstance()) {
          const fbUi = new firebaseui.auth.AuthUI(auth);
          fbUi.start('#firebaseui', uiConfig); 
      } else {
          const fbUi = firebaseui.auth.AuthUI.getInstance();
          fbUi.start('#firebaseui', uiConfig)
      }
  },
  data() {
    return {
        
    }
  }
}
</script>

<style>
    #wrapper {
        display: flex;
        flex-flow: column nowrap;
        align-items: center;
        justify-content: center;
        height: 80vh;
        width: 100vw;
        background: #333;
    }
</style>
