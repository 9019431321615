<template>
  <div id="app">
    <b-navbar>
      <b-navbar-brand>
        <router-link to="/">
          <img class="logo" src="@/assets/images/logo.png" alt="1Up Trailers">
        </router-link>
      </b-navbar-brand>
      <b-navbar-nav class="ml-auto">
        <b-collapse is-nav>
          <b-nav-item-dropdown variant="outline-primary" :text="user ? trim(user.displayName) : 'Sign In'" right>
            <b-dropdown-item v-if="!user" to="/logIn">Log In</b-dropdown-item>
            <b-dropdown-item v-if="user" v-on:click="logOut()">Log Out</b-dropdown-item>
          </b-nav-item-dropdown>
        </b-collapse>
      </b-navbar-nav>
    </b-navbar>
    <router-view></router-view>
    <footer>
      <div class="footer-left">
        <div class="copyright">© 2020 Inkastar LLC</div>
        <div><router-link :to="{name: 'Terms'}">Terms and Policies</router-link></div>
      </div>
      <div class="social">
        <a href="https://www.facebook.com/1Up-Trailer-112760306864259" target="_blank">
          <font-awesome-icon :icon="['fab', 'facebook']"></font-awesome-icon>
        </a>
      </div>
    </footer>
  </div>
</template>

<script>
import DBHelper from './services/db';
import { auth } from './services/firebase';

let db = new DBHelper();

export default {
  name: 'App',
  methods: {
    logOut: async function () {
      await auth.signOut();
      this.user = auth.currentUser;
      this.showLoggedOut = 5;
    },
    trim: function (name) {
      if (name.length > 15) {
        return name.substring(0, 12) + '...';
      } else {
        return name;
      }
    }
  },
  mounted: function() {
    const self = this;
    auth.onAuthStateChanged(async function(user) {
      if (user) {
        user.isAdmin = await db.isUserAdmin(user);
      }
      self.user = user;
    });
  },
  data() {
    return {
        user: auth.currentUser,
    }
  }
}
</script>

<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: white;
}

.noselect {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.logo {
  height: 54px;
  margin: 0 20px;
}

.navbar, .dropdown-menu, .dropdown-menu li {
  background-color: #555 !important;
  color: white !important;

  a {
    background-color: #555 !important;  
    color: white !important;
  }
}

.dropdown-menu li:hover {
  background-color: #888 !important;
}

footer {
  display: flex !important;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;
  padding: 20px 4vw;
  width: 100%;
  background-color: #555;
  color: white;

  .fa-facebook {
    font-size: 24pt;
    color: white;
  }

  .footer-left a {
    color: white;
  }
}

</style>
