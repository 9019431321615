import firebase from 'firebase/compat/app'
import 'firebase/compat/auth'
import 'firebase/compat/firestore'

// firebase init - add your own config here
const firebaseConfig = {
    apiKey: "AIzaSyBS22dGASu00KinRtrFm9mn-cXsSMJNULc",
    authDomain: "uptrailer-86e85.firebaseapp.com",
    databaseURL: "https://uptrailer-86e85.firebaseio.com",
    projectId: "uptrailer-86e85",
    storageBucket: "uptrailer-86e85.appspot.com",
    messagingSenderId: "25797000289",
    appId: "1:25797000289:web:1996c26e201e79d0673e20"
}
firebase.initializeApp(firebaseConfig)

// services
const db = firebase.firestore()
const auth = firebase.auth()

// collection references
const movieCollection = db.collection('movies')
const trailerCollection = db.collection('trailers')
const userCollection = db.collection('users')

// helpful utils
const increment = firebase.firestore.FieldValue.increment
const arrayUnion = firebase.firestore.FieldValue.arrayUnion
const arrayRemove = firebase.firestore.FieldValue.arrayRemove

// export utils/refs
export {
  db,
  auth,
  movieCollection,
  trailerCollection,
  userCollection,
  increment,
  arrayUnion,
  arrayRemove
}