import { db, movieCollection, trailerCollection, userCollection, arrayUnion, arrayRemove } from "./firebase";

export default class DBHelper {
    async getMovies(callback) {
        movieCollection.onSnapshot(async function(snapshot) {
            var movies = [];
            snapshot.forEach((m) => {
                var movie = m.data();
                var movieObject = {
                    movieBanner: movie.movieBanner,
                    movieName: movie.movieName,
                    movieDate: movie.movieDate,
                    moviePoster: movie.moviePoster,
                    movieId: m.id,
                };
                movies.push(movieObject);
            });
            callback(movies);
        });
    }

    async getMovieById(id, callback) {
        movieCollection.doc(id).onSnapshot(async function(snapshot) {
            var movie = snapshot.data();
            var movieObject = {
                movieBanner: movie.movieBanner,
                movieName: movie.movieName,
                movieDate: movie.movieDate,
                moviePoster: movie.moviePoster,
                movieDistributor: movie.movieDistributor,
                moviePublisher: movie.moviePublisher,
                movieId: id,
            };
            callback(movieObject);
        });
    }

    async getTrailersForMovie(id, callback) {
        trailerCollection.where('trailerMovie', '==', movieCollection.doc(id))
            .onSnapshot(function(snapshot) {
                var trailers = [];
                snapshot.forEach((t) => {
                    var trailer = t.data();
                    trailers.push({
                        accuracyUp: trailer.accuracyUp == null ? [] : trailer.accuracyUp,
                        accuracyDown: trailer.accuracyDown == null ? [] : trailer.accuracyDown,
                        spoilersUp: trailer.spoilersUp == null ? [] : trailer.spoilersUp,
                        spoilersDown: trailer.spoilersDown == null ? [] : trailer.spoilersDown,
                        title: trailer.trailerName,
                        youtubeId: trailer.trailerYoutubeId,
                        date: trailer.trailerDate,
                        trailerId: t.id
                    });
                });
                return callback(trailers);
            })
    }

    async addMovie(movie) {
        movieCollection.add(movie);
    }

    async addTrailer(trailer) {
        trailer.trailerMovie = db.doc('movies/' + trailer.trailerMovie);
        trailer.accuracyUp = [];
        trailer.accuracyDown = [];
        trailer.spoilersUp = [];
        trailer.spoilersDown = [];

        trailerCollection.add(trailer);
    }

    async updateTrailer(trailerId, trailer) {
        trailerCollection.doc(trailerId).update(trailer);
    }

    async deleteTrailer(trailerId) {
        trailerCollection.doc(trailerId).delete();
    }

    async updateMovie(movieId, movie) {
        movieCollection.doc(movieId).update(movie);
    }

    async rateAccuracy(trailerId, uid, isUp) {
        var trailer = trailerCollection.doc(trailerId);
        if (isUp) {
            trailer.update({
                accuracyUp: arrayUnion(uid),
                accuracyDown: arrayRemove(uid)
            });    
        } else {
            trailer.update({
                accuracyDown: arrayUnion(uid),
                accuracyUp: arrayRemove(uid)
            });    
        }
    }

    async rateSpoilers(trailerId, uid, isUp) {
        var trailer = trailerCollection.doc(trailerId);
        if (isUp) {
            trailer.update({
                spoilersUp: arrayUnion(uid),
                spoilersDown: arrayRemove(uid)
            });    
        } else {
            trailer.update({
                spoilersDown: arrayUnion(uid),
                spoilersUp: arrayRemove(uid)
            });    
        }
    }

    async isUserAdmin(user) {
        const userDoc = await userCollection.doc(user.uid).get();
        return userDoc.data() && userDoc.data().role == 'admin';
    }
}